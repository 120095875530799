<script setup lang="ts">
//...

import site from '~~/site'
import { usaMobileDisplayFormat } from '~~/app/utils/index'

const { name, description, mobile, email, sns } = site
</script>
<template>
  <footer class="pt-12">
    <div class="container mx-auto px-8">
      <div class="flex flex-wrap align-top relative">
        <!-- description -->
        <div class="flex flex-col flex-wrap items-center p-4 md:flex-col md:flex-nowrap -mt-4 lg:w-1/3">
          <!-- <NuxtLink class="-ml-6" to="/">
            <div to="/" class="font-serif inline-flex items-center text-3xl">
              <TheLogo />
             
            </div>
          </NuxtLink> -->
          <TheLogo />
          <p class="mt-2 text-center w-full lg:text-left">
            {{ description }}
          </p>
          <div class="inline-flex mt-6 space-x-3">
            <div class="flex flex-wrap space-x-4">
              <NuxtLink v-if="sns.facebook" :href="sns.facebook" aria-label="facebook" external target="_blank">
                <BaseIcon name="i-logos-facebook" class="text-2xl" width="28px" />
              </NuxtLink>
              <NuxtLink v-if="sns.twitter" :href="sns.twitter" aria-label="twitter" external target="_blank">
                <BaseIcon name="i-logos-twitter" class="text-2xl" width="28px" />
              </NuxtLink>
              <NuxtLink v-if="sns.instagram" :href="sns.instagram" aria-label="instagram" external target="_blank">
                <BaseIcon name="i-skill-icons-instagram" class="text-2xl" width="28px" />
              </NuxtLink>
              <NuxtLink v-if="sns.youtube" :href="sns.youtube" aria-label="youtube" external target="_blank">
                <BaseIcon name="i-logos-youtube-icon" class="text-2xl" width="28px" />
              </NuxtLink>
            </div>
          </div>
        
        </div>

        <!-- Links -->
        <div class="flex flex-col items-center p-4 w-full sm:w-1/2 md:flex-1 md:w-1/3 lg:items-start">
          <h2 class="font-bold text-primary-600 dark:text-primary-200 text-xl">
            Links
          </h2>
          <ul class="text-center lg:mt-2 lg:text-left">
            <li class="mb-4">
              <NuxtLink to="/faq" class="hover:text-primary-600 dark:text-primary-200">FAQ</NuxtLink>
            </li>
            <li class="mb-4">
              <NuxtLink to="/locations" class="hover:text-primary-600 dark:text-primary-200">Locations Serviced</NuxtLink>
            </li>
            <li class="mb-4">
              <NuxtLink to="/gallery" class="hover:text-primary-600 dark:text-primary-200">Gallery</NuxtLink>
            </li>
          </ul>
        </div>

        <!--  Text or Call Us -->
        <div class="flex flex-col items-center p-4 w-full sm:w-1/2 md:flex-1 md:w-1/3 lg:items-start">
          <h2 class="font-bold text-primary-600 dark:text-primary-200 text-xl">
            Text or Call Us
          </h2>
          <ul class="text-center lg:mt-2 lg:text-left">
            <li class="mb-4">
              <NuxtLink :to="'tel:' + mobile" class="flex items-center justify-center lg:justify-start"> <BaseIcon name="i-ic:baseline-phone" class="text-2xl mr-1" width="18px" /> {{ usaMobileDisplayFormat(mobile) }}</NuxtLink>
            </li>
            <li v-if="email" class="mb-4">
              <NuxtLink :to="'mailto:' + email" class="mb-4 flex items-center justify-center lg:justify-start"><BaseIcon name="i-ic:baseline-email" class="text-2xl mr-1" width="18px" />{{ email }}</NuxtLink>
            </li>
          </ul>
        </div>
      </div>

      <div class="text-center">
          <!-- <hr class="mb-4 opacity-25"> -->
          <p class="py-1 text-sm sm:text-center">
            &copy;2024. All Rights Reserved - {{ name }}
          </p>
        </div>
    </div>
  </footer>
</template>
<style scoped></style>
